import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

function Show(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M8.00002 10.6667C8.83336 10.6667 9.54169 10.375 10.125 9.79167C10.7084 9.20834 11 8.50001 11 7.66667C11 6.83334 10.7084 6.12501 10.125 5.54167C9.54169 4.95834 8.83336 4.66667 8.00002 4.66667C7.16669 4.66667 6.45836 4.95834 5.87502 5.54167C5.29169 6.12501 5.00002 6.83334 5.00002 7.66667C5.00002 8.50001 5.29169 9.20834 5.87502 9.79167C6.45836 10.375 7.16669 10.6667 8.00002 10.6667ZM8.00002 9.46667C7.50002 9.46667 7.07502 9.29167 6.72502 8.94167C6.37502 8.59167 6.20002 8.16667 6.20002 7.66667C6.20002 7.16667 6.37502 6.74167 6.72502 6.39167C7.07502 6.04167 7.50002 5.86667 8.00002 5.86667C8.50002 5.86667 8.92502 6.04167 9.27502 6.39167C9.62502 6.74167 9.80002 7.16667 9.80002 7.66667C9.80002 8.16667 9.62502 8.59167 9.27502 8.94167C8.92502 9.29167 8.50002 9.46667 8.00002 9.46667ZM8.00002 12.6667C6.45558 12.6667 5.04447 12.2583 3.76669 11.4417C2.48891 10.625 1.52224 9.52223 0.866689 8.13334C0.833356 8.07778 0.811133 8.00834 0.800022 7.92501C0.788911 7.84167 0.783356 7.75556 0.783356 7.66667C0.783356 7.57778 0.788911 7.49167 0.800022 7.40834C0.811133 7.32501 0.833356 7.25556 0.866689 7.20001C1.52224 5.81112 2.48891 4.70834 3.76669 3.89167C5.04447 3.07501 6.45558 2.66667 8.00002 2.66667C9.54447 2.66667 10.9556 3.07501 12.2334 3.89167C13.5111 4.70834 14.4778 5.81112 15.1334 7.20001C15.1667 7.25556 15.1889 7.32501 15.2 7.40834C15.2111 7.49167 15.2167 7.57778 15.2167 7.66667C15.2167 7.75556 15.2111 7.84167 15.2 7.92501C15.1889 8.00834 15.1667 8.07778 15.1334 8.13334C14.4778 9.52223 13.5111 10.625 12.2334 11.4417C10.9556 12.2583 9.54447 12.6667 8.00002 12.6667Z" />
    </SvgIcon>
  )
}

export default Show
